import { CONSOLE_MODE, CURRENT_PAGE, fetchDataFromServer, getCookie, setCookie, shuffleArray } from "../module";
const { showLoadingDialog, hideLoadingDialog, alertDialog, closeAlertDialog, createSmallPreloaderDialog, removeDialog } = require("../dialogs");

const shareBtn = document.getElementById("share-btn");
// Define the URL and options

const typeString = ['Explore BibleGPT, an AI-powered platform offering deep insights and interpretations of biblical texts. Dive into the world of scripture with the power of AI.','BibleGPT is an innovative AI-powered tool that revolutionizes Bible study, theology, and Christian living.', 'It offers accurate answers, personalized guidance, and profound insights, fostering an enriched understanding of the scriptures and nurturing spiritual development.'];

document.addEventListener('DOMContentLoaded', function(){
    // hideLoadingDialog();
    if(CURRENT_PAGE === "index.html" || CURRENT_PAGE === "index" || CURRENT_PAGE === ""){
      var typed = new Typed('#multiple-Text', {
        strings: typeString,
        typeSpeed:100,
        backSpeed:100,
        backDelay:1000,
        loop:true,
      });
      const top20QuestionsData = getCookie("Top20Questions");
      if(top20QuestionsData === null || top20QuestionsData === undefined){
        loadTop20Questions();
      } else {
        const top20QuestionsDataJSON = JSON.parse(top20QuestionsData);
        //if (CONSOLE_MODE) console.log(top20QuestionsDataJSON);
        createCardsForTop20Questions(top20QuestionsDataJSON);
      }
    }
});

// shareBtn.addEventListener('click', function() {
//     // Create share message with text and link
//     let shareMessage = 'The BibleGPT AI provides accurate Bible-based answers. Its valuable for Bible study, theology, and Christian living. \n' + 'https://biblegpt-la.com';
//     // Check if Web Share API is supported by the browser
//     if (navigator.share) {
//         // Share message using Web Share API
//         navigator.share({
//             title: 'BibleGPT',
//             text: shareMessage,
//         })
//     .then(() => if (CONSOLE_MODE) console.log('Message shared successfully.'))
//     .catch(error => if (CONSOLE_MODE) console.log('Error sharing message:', error));
//     } else {
//         // Fallback for browsers that do not support Web Share API
//         alert('Share functionality not supported by your browser.');
//     }
// });
  
//window.addEventListener('load', excuteAppStoreDialog);
document.addEventListener("DOMContentLoaded", function() {
  var acc = document.getElementsByClassName("accordion");
  if(acc) {
      for (let i = 0; i < acc.length; i++) {
          acc[i].addEventListener("click", function() {

              // Close all panels except the clicked one
              for (let j = 0; j < acc.length; j++) {
                  if (acc[j] === this) continue;
                  acc[j].classList.remove("active");
                  acc[j].nextElementSibling.classList.remove("show");
                  acc[j].querySelector(".icon").classList.remove("bx-minus");
                  acc[j].querySelector(".icon").classList.add("bx-plus");
              }

              // Toggle clicked panel
              this.classList.toggle("active");
              var panel = this.nextElementSibling;

              if (panel.classList.contains("show")) {
                  panel.classList.remove("show");
                  this.querySelector(".icon").classList.remove("bx-minus");
                  this.querySelector(".icon").classList.add("bx-plus");
              } else {
                  panel.classList.add("show");
                  this.querySelector(".icon").classList.remove("bx-plus");
                  this.querySelector(".icon").classList.add("bx-minus");
              }
          });
      }
  }
});


function loadTop20Questions(){
  // Call the fetchData function
  fetchDataFromServer("/web/data/top-questions", {
    method: 'POST', // Specify the HTTP method
    body: ""
  })
  .then(data => {
      // Handle the fetched data
      if (CONSOLE_MODE) console.log('Fetched data:', data);
      // Call the function with the cardData array
      createCardsForTop20Questions(data.data.questions);
      setCookie("Top20Questions", JSON.stringify(data.data.questions), 7);
  })
  .catch(error => {
      // Handle errors
      console.error('Error fetching data:', error);
  });
}

// Function to create HTML elements dynamically
function createCardsForTop20Questions(data) {
  const container = document.getElementById("top-question-content");

  // Shuffle the data array
  const shuffledData = shuffleArray(data);

  // Select the first 10 items from the shuffled array
  const selectedData = shuffledData.slice(0, 18);

  // Loop through the selected data array
  selectedData.forEach(card => {
      // Create elements
      if(card.question.trim().length < 60){
        const anchor = document.createElement("a");
        anchor.href = `chat.html?query=${encodeURIComponent(card.question)}`;
        anchor.setAttribute("data-aos", "fade-up");
        anchor.setAttribute("data-aos-anchor-placement", "top-bottom");
        const cardDiv = document.createElement("div");
        cardDiv.classList.add("card");
        const icon = document.createElement("i");
        icon.classList.add("bx", "bxs-message-rounded-dots");
        const text = document.createElement("p");
        text.textContent = card.question;
        // Append elements
        cardDiv.appendChild(icon);
        cardDiv.appendChild(text);
        anchor.appendChild(cardDiv);
        container.appendChild(anchor);
      }
  });
}